import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/Button';
import { visibility } from 'shared/enhancers';

const MultipleFieldItems = ({
  Component,
  lineItem,
  name,
  values,
  remove,
  readonly,
  ...props
}) => (
  <React.Fragment>
    {values.map((_, index) => (
      <fieldset className="fieldset" key={`${name + index}`}>
        <legend>
          {lineItem} {index + 1}
          {!readonly && (
            <Button
              aria-label="Remove"
              className="btn-light icon rounded fieldset-right-icon"
              buttonStyle="link"
              onClick={() => remove(index)}
              outline
              color="danger"
              title="Remove"
            >
              <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
            </Button>
          )}
        </legend>
        <Component
          {...props}
          key={`${name + index}`}
          index={index}
          name={`${name}[${index}]`}
        />
      </fieldset>
    ))}
  </React.Fragment>
);
MultipleFieldItems.propTypes = {
  Component: PropTypes.func.isRequired,
  lineItem: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remove: PropTypes.func.isRequired,
  readonly: PropTypes.bool,
};

MultipleFieldItems.defaultProps = {
  readonly: false,
};

export default visibility(MultipleFieldItems);
