import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/Button';
import { visibility } from 'shared/enhancers';

const SingleFieldItems = ({ Component, name, values, remove, ...props }) => (
  <React.Fragment>
    {values.map((_, index) => (
      <div className="single-field" key={`${name + index}`}>
        <Component {...props} index={index} name={`${name}[${index}]`} />
        <Button
          aria-label="Remove"
          className="btn-light icon rounded-pill fieldset-right-icon"
          buttonStyle="link"
          onClick={() => remove(index)}
          outline
          color="danger"
          title="Remove"
        >
          <FontAwesomeIcon icon={faMinusCircle} className="text-danger" />
        </Button>
      </div>
    ))}
  </React.Fragment>
);
SingleFieldItems.propTypes = {
  Component: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remove: PropTypes.func.isRequired,
};

export default visibility(SingleFieldItems);
