import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './DefinitionList.scss';

const DefinitionList = props => {
  const { tableLayout, terms } = props;
  return (
    <dl className={classnames({ 'definition-table': tableLayout })}>
      {terms.map((term, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>
          <dt>{term.name}: </dt>
          <dd>{term.definition}</dd>
        </div>
      ))}
    </dl>
  );
};

DefinitionList.propTypes = {
  tableLayout: PropTypes.bool,
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number,
      name: PropTypes.string,
      definition: PropTypes.string,
    })
  ),
};

DefinitionList.defaultProps = {
  tableLayout: false,
  terms: [],
};

export default DefinitionList;
