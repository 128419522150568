import React from 'react';
import { render } from 'react-dom';

// This needs to be the first stylesheet imported so component stylesheets
// will overwrite it without having to add extra specificity.
import 'scss/app.scss';

import startApp from 'infrastructure/start';
import { configureStore } from 'infrastructure/redux';
import { getCookie, setCookie } from 'infrastructure/security/cookies';
import { errorHandler, ErrorPage } from 'infrastructure/error';

import App from 'containers/App';

import * as serviceWorker from './serviceWorker';

const initialstate = getCookie('app') ? JSON.parse(getCookie('app')) : {};
const configuredStore = configureStore(initialstate);

configuredStore.subscribe(() => {
  const state = configuredStore.getState();
  if (state.account) {
    const { account, dataTable } = state;
    setCookie(
      'app',
      JSON.stringify({ account, dataTable }),
      account.expires_in * 60
    );
  }
});

if (process.env.NODE_ENV === 'production') {
  const onerror = errorHandler(configuredStore);
  global.onerror = onerror;
}

startApp(
  store => render(<App store={store} />, document.getElementById('root')),
  configuredStore,
  () => render(<ErrorPage canRetry />, document.getElementById('root'))
);

serviceWorker.unregister({
  onUpdate: () =>
    configuredStore.dispatch({
      type: 'SW_UPDATE_AVAILABLE',
    }),
  onSuccess: () =>
    configuredStore.dispatch({
      type: 'SW_INSTALLED',
    }),
});
