import React from 'react';
import { CardTitle, CardText } from 'reactstrap';
import { Panel } from 'components';
import PropTypes from 'prop-types';
import './BlankSlate.scss';

function BlankSlate({ title, message, cta }) {
  return (
    <Panel body className="blank-slate">
      <CardTitle tag="h2">{title}</CardTitle>
      <CardText>{message}</CardText>
      {cta}
    </Panel>
  );
}

BlankSlate.propTypes = {
  /** Button or link to lead the user on to a desired flow */
  cta: PropTypes.node,
  /** Message to give more context */
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Title of the blank slate, very brief description of where the user is to give context */
  title: PropTypes.string,
};

BlankSlate.defaultProps = {
  cta: null,
  message: null,
  title: null,
};

export default BlankSlate;
