export default (cdnUrl, assetPath) => {
  if (
    assetPath.indexOf('http://') === 0 ||
    assetPath.indexOf('https://') === 0
  ) {
    return assetPath;
  }

  return `${cdnUrl}${assetPath}`;
};
