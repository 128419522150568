import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Popover as ReactstrapPopover } from 'reactstrap';
import { Button } from 'components';
// import PropTypes from 'prop-types';

class Popover extends PureComponent {
  constructor() {
    super();
    this.state = {
      popoverOpen: false,
    };
    this.handleFocus = this.handleFocus.bind(this);
    this.toggle = this.toggle.bind(this);
    // this.handleBlur = this.handleBlur.bind(this);
    this.setButtonRef = this.setButtonRef.bind(this);
    this.setContentsRef = this.setContentsRef.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.toggle);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.toggle);
  }

  setButtonRef(node) {
    this.buttonRef = node;
  }

  setContentsRef(node) {
    this.contentsRef = node;
  }

  toggle = event => {
    const { popoverOpen } = this.state;
    // handle closed state
    // only open if popover closed, the buttonRef exists and the click handler target contains the button
    if (
      !popoverOpen &&
      this.buttonRef &&
      this.buttonRef.contains(event.target)
    ) {
      // open
      this.setState({
        popoverOpen: true,
      });
      return; // don't immediately close the popover
    }
    // handle open state
    // only close if popover is open
    if (popoverOpen) {
      // check the buttonRef exists and if it's a click on the button
      if (this.buttonRef && this.buttonRef.contains(event.target)) {
        this.setState({
          popoverOpen: false,
        });
      }
      // check the contentsRef exists and that it's NOT being clicked on
      if (this.contentsRef && !this.contentsRef.contains(event.target)) {
        this.setState({
          popoverOpen: false,
        });
      }
    }
  };

  // handleBlur = event => {
  //   // close the popover if the blur is NOT triggered by a click inside the picker
  //   console.log(event.type);
  //   if (this.contentsRef && this.contentsRef.contains(event.target)) {
  //     console.log('2');
  //     this.setState({
  //       popoverOpen: false,
  //     });
  //   }
  // };

  handleFocus = () => {
    this.setState({
      popoverOpen: true,
    });
  };

  render() {
    const { buttonClassname, children, id, trigger } = this.props;
    const { popoverOpen } = this.state;

    return (
      <React.Fragment>
        <Button
          className={buttonClassname}
          color="light"
          id={id}
          ref={this.ButtonRef}
          type="button"
          // onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        >
          {trigger}
        </Button>
        <ReactstrapPopover
          className="color-picker__popover"
          isOpen={popoverOpen}
          placement="bottom"
          target={id}
        >
          <div ref={this.setContentsRef}>{children}</div>
        </ReactstrapPopover>
      </React.Fragment>
    );
  }
}

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  buttonClassname: PropTypes.string,
  id: PropTypes.string.isRequired,
  trigger: PropTypes.node.isRequired,
};

Popover.defaultProps = {
  buttonClassname: '',
};

export default Popover;
