import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from 'reactstrap';
import './Panel.scss';

const Panel = props => {
  const {
    backgroundColor,
    panelActions,
    children,
    className,
    headerBackground,
    imgBottom,
    imgTop,
    onClick,
    overlayContent,
    subtitle,
    tag,
    title,
    topBorder,
  } = props;
  return (
    <Card
      className={classnames(
        {
          [`bg-${backgroundColor}`]: backgroundColor,
          [`border-${topBorder}`]: topBorder,
          'top-border': topBorder,
          clip: overlayContent,
        },
        'panel',
        className
      )}
      onClick={onClick}
      tag={tag}
    >
      {imgTop}
      <div
        className={classnames({
          'card-img-overlay': overlayContent && (imgBottom || imgTop),
        })}
      >
        {(title || subtitle) && (
          <CardHeader
            className={classnames({
              [`bg-${headerBackground}`]: headerBackground,
            })}
          >
            <div>
              {title && (
                <CardTitle tag="h2" className="h5">
                  {title}
                </CardTitle>
              )}
              {subtitle && <CardSubtitle tag="h6">{subtitle}</CardSubtitle>}
            </div>
            {panelActions && (
              <div className="panel-actions">{panelActions}</div>
            )}
          </CardHeader>
        )}
        <CardBody>{children}</CardBody>
      </div>
      {imgBottom}
    </Card>
  );
};

Panel.propTypes = {
  backgroundColor: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  panelActions: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  headerBackground: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
  imgBottom: PropTypes.node,
  imgTop: PropTypes.node,
  onClick: PropTypes.func,
  overlayContent: PropTypes.bool,
  subtitle: PropTypes.string,
  tag: PropTypes.node,
  title: PropTypes.string,
  topBorder: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
  ]),
};

Panel.defaultProps = {
  backgroundColor: null,
  panelActions: null,
  children: null,
  className: '',
  headerBackground: null,
  imgBottom: null,
  imgTop: null,
  onClick: () => false,
  overlayContent: false,
  subtitle: '',
  tag: 'div',
  title: '',
  topBorder: null,
};

export default Panel;
