import merge from 'lodash/merge';

import {
  CHANGE_THEME_TO_DARK,
  CHANGE_THEME_TO_LIGHT,
  CHANGE_THEME_TO_LIGHTONDARK,
  MODIFY_THEME,
} from './themeActions';

const initialState = {
  className: 'theme-lightondark',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHANGE_THEME_TO_DARK:
      return { ...state, className: 'theme-dark' };
    case CHANGE_THEME_TO_LIGHT:
      return { ...state, className: 'theme-light' };
    case CHANGE_THEME_TO_LIGHTONDARK:
      return { ...state, className: 'theme-lightondark' };
    case MODIFY_THEME:
      return {
        ...state,
        theme: merge({}, state.theme, action.payload),
      };
    default:
      return state;
  }
}
