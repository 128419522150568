import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/pro-solid-svg-icons';
import Button from 'components/Button';
import { Img } from 'components';

class DropZoneField extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    emptyMessage: PropTypes.node.isRequired,
    fieldProps: PropTypes.shape({}),
    multiple: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    ]),
  };

  static defaultProps = {
    className: '',
    fieldProps: null,
    value: '',
  };

  constructor() {
    super();
    this.state = {};
  }

  removeFile(index, e) {
    const { onChange, value } = this.props;
    e.preventDefault();
    onChange(value.filter((val, i) => i !== index));
  }

  render() {
    const {
      className,
      emptyMessage,
      fieldProps,
      name,
      multiple,
      onChange,
      value,
      onFileDialogCancel,
    } = this.props;
    const files = value;

    return (
      <div
        className={classnames(
          'dropzone',
          'form-control',
          className,
          { single: !fieldProps.multiple },
          { multiple: fieldProps.multiple }
        )}
      >
        <Dropzone
          {...fieldProps}
          className="dropzone-input"
          inputProps={{ id: name }}
          multiple={multiple}
          name={name}
          onDrop={filesToUpload => {
            onChange(filesToUpload);
          }}
          onFileDialogCancel={() => {
            onFileDialogCancel();
          }}
          activeStyle={{ border: '2px solid green' }}
        >
          {(!files || files.length === 0) && (
            <div className="dropzone-drop-here">{emptyMessage}</div>
          )}
        </Dropzone>
        {files && Array.isArray(files) && files.length > 0 && (
          <div className="files-wrapper">
            {files.map((file, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="file-preview" key={i}>
                <a
                  href={file.preview}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Img
                    blurFill
                    lightenBackground
                    src={file.preview}
                    alt={file.name}
                    className="file-thumbnail"
                  />
                </a>
                <small className="file-name">{file.name}</small>
                <Button
                  className="remove btn-icon rounded-circle"
                  color="danger"
                  icon={<FontAwesomeIcon icon={faMinusCircle} />}
                  onClick={e => this.removeFile(i, e)}
                  size="sm"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

const renderDropZoneField = props => {
  const { className, emptyMessage, fieldProps, input, multiple } = props;
  return (
    <DropZoneField
      {...input}
      className={className}
      emptyMessage={emptyMessage}
      fieldProps={fieldProps}
      multiple={multiple}
    />
  );
};

renderDropZoneField.propTypes = {
  className: PropTypes.string,
  emptyMessage: PropTypes.node.isRequired,
  fieldProps: PropTypes.shape({}),
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    onFileDialogCancel: PropTypes.func,
  }).isRequired,
  multiple: PropTypes.bool,
};

renderDropZoneField.defaultProps = {
  className: '',
  fieldProps: null,
  multiple: false,
};

export default renderDropZoneField;
