import { connect } from 'react-redux';
import { compose } from 'recompose';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router';
import injectReducer from 'infrastructure/redux/injectReducer';

import reducer from './reducer';
import { makeSelectCurrentPath } from './selectors';

const mapStateToProps = createStructuredSelector({
  path: makeSelectCurrentPath(),
});

const withConnect = connect(mapStateToProps);

const withReducer = injectReducer({ key: 'menu', reducer });

const enhance = compose(
  withRouter,
  withReducer,
  withConnect
);

export default enhance;
