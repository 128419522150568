import { uniq } from 'lodash';
import states from './states';

const update = (items, u) => {
  const result = uniq(u(items));

  return {
    totalCount: result.length,
    result,
  };
};

const replace = (items, payload) =>
  update(items, i => [...i.filter(x => x.id !== payload.id), payload]);

export default ({
  GET_LIST,
  GET_ADMIN_LIST,
  DELETE,
  UPDATE,
  GET,
  GET_ADMIN,
  CREATE,
}) => ({
  [GET_LIST]: (_, payload) => ({
    ...payload,
    status: states.loaded,
  }),
  [GET_ADMIN_LIST]: (_, payload) => ({
    ...payload,
    status: states.loaded,
  }),
  [DELETE]: ({ items, ...state }, _, meta) => ({
    ...state,
    ...update(items, i => i.filter(x => x.id !== meta.id)),
    status: states.loaded,
  }),
  [CREATE]: ({ items, ...state }, payload) => ({
    ...state,
    ...update(items, i => [...i, payload]),
    status: states.loaded,
  }),
  [UPDATE]: ({ items, ...state }, payload) => ({
    ...state,
    ...replace(items, payload),
    status: states.loaded,
  }),
  [GET]: ({ items, ...state }, payload) => ({
    ...state,
    ...update(items, i => [...i, payload]),
    status: states.loaded,
  }),
  [GET_ADMIN]: ({ items, ...state }, payload) => ({
    ...state,
    ...update(items, i => [...i, payload]),
    status: states.loaded,
  }),
  default: state => state,
});
