import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { Secured } from 'infrastructure/security';
import { ErrorBoundary, ErrorPage } from 'infrastructure/error';

import Dashboard from 'containers/Dashboard/index';
import Layout from 'containers/Layout';
import LogIn from 'slices/Account/Loadable';
import MainWrapper from 'containers/App/MainWrapper';
import IdentityUser from 'slices/IdentityUser/Loadable';
import IdentityRole from 'slices/IdentityRole/Loadable';
import Network from 'slices/Network/Root';
import Franchise from 'slices/Franchise/Loadable';
import Movie from 'slices/Movie/Loadable';
import CastMember from 'slices/CastMember/Loadable';
import HomeScreen from 'slices/HomeScreen/Loadable';
import Branding from 'slices/Branding/Loadable';
import Hero from 'slices/Hero/Loadable';
import Provider from 'slices/Provider/Loadable';
import TimeZone from 'slices/TimeZone/Loadable';
import Sponsor from 'slices/Sponsor/Loadable';
import AdUnit from 'slices/AdUnit/Loadable';
import PushNotifications from 'slices/PushNotifications/Loadable';
import SuggestedSearches from 'slices/SuggestedSearches/Loadable';
import Review from 'slices/Review/Loadable';
import User from 'slices/User/Loadable';
import WatchParty from 'slices/WatchParty/Loadable';
import WatchPartyAsset from 'slices/WatchPartyAsset/Loadable';
import Widget from 'slices/Widget/Loadable';
import Show from 'slices/Show/Loadable';
import Episode from 'slices/Episode/Loadable';
import ArEntry from 'slices/ArEntry/Loadable';
// import { Form as WatchPartyStickyMessageForm } from 'slices/WatchPartyStickyMessage/Loadable';
import WatchPartyStickyMessage from 'slices/WatchPartyStickyMessage/Loadable';

const wrappedRoutes = () => (
  <Secured>
    <React.Fragment>
      <Layout />
      <div className="container__wrap">
        <ErrorBoundary>
          <Route path="/" component={Dashboard} exact />
          <Route path="/dashboard" component={Dashboard} />
          <Route
            path="/identity"
            render={() => <Redirect to="/identity/user" />}
            exact
          />
          <Route path="/identity/role" component={IdentityRole} />
          <Route path="/identity/user" component={IdentityUser} />
          <Route path="/network" component={Network} />
          <Route path="/franchise" component={Franchise} />
          <Route path="/movie" component={Movie} />
          <Route path="/cast-member" component={CastMember} />
          <Route path="/home-screen" component={HomeScreen} />
          <Route path="/branding" component={Branding} />
          <Route path="/hero" component={Hero} />
          <Route path="/push-notifications" component={PushNotifications} />
          <Route
            path="/sponsors"
            render={() => <Redirect to="/sponsors/sponsor" />}
            exact
          />
          <Route path="/sponsors/sponsor" component={Sponsor} />
          <Route path="/sponsors/ad-unit" component={AdUnit} />
          <Route path="/provider" component={Provider} />
          <Route path="/time-zone" component={TimeZone} />
          <Route path="/suggested-searches" component={SuggestedSearches} />
          <Route path="/review" component={Review} />
          <Route
            path="/watch-parties"
            render={() => <Redirect to="/watch-parties/manage" />}
            exact
          />
          <Route
            path="/watch-parties/assets"
            render={() => <Redirect to="/watch-parties/assets/emojis" />}
            exact
          />
          <Route
            path="/watch-parties/assets/emojis"
            component={WatchPartyAsset}
          />
          <Route
            path="/watch-parties/assets/gifs"
            component={WatchPartyAsset}
          />
          <Route
            path="/watch-parties/assets/stickers"
            component={WatchPartyAsset}
          />
          <Route path="/watch-parties/manage" component={WatchParty} />
          <Route
            path="/watch-party-sticky-messages"
            component={WatchPartyStickyMessage}
          />
          <Route path="/widget" component={Widget} />
          <Route path="/show" component={Show} />
          <Route path="/episode" component={Episode} />
          <Route path="/ar-entry" component={ArEntry} />
          <Route path="/user" component={User} />
        </ErrorBoundary>
      </div>
    </React.Fragment>
  </Secured>
);

const AppRouter = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={LogIn} />
        <Route path="/error" component={ErrorPage} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default AppRouter;
