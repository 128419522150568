import React from 'react';
import Loadable from 'react-loadable';
import Loading from 'components/Loading';

export default Loadable({
  loader: () => import('./index'),
  loading: () => <Loading visible />,
});

export const EmojiForm = Loadable({
  loader: () => import('./containers/EmojiFormPage'),
  loading: () => <Loading visible />,
});

export const StickerForm = Loadable({
  loader: () => import('./containers/SitckerFormPage'),
  loading: () => <Loading visible />,
});

export const GifForm = Loadable({
  loader: () => import('./containers/GifFormPage'),
  loading: () => <Loading visible />,
});

export const EmojiList = Loadable({
  loader: () => import('./containers/EmojiListPage'),
  loading: () => <Loading visible />,
});

export const StickerList = Loadable({
  loader: () => import('./containers/StickerListPage'),
  loading: () => <Loading visible />,
});

export const GifList = Loadable({
  loader: () => import('./containers/GifListPage'),
  loading: () => <Loading visible />,
});
