import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { ChromePicker } from 'react-color';
import { FormFeedback } from 'reactstrap';

import { Popover } from 'components';
import './ColorPicker.scss';

class ChromeColorPickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    value: '',
  };

  constructor(props) {
    super();
    this.state = {
      color: props.value || '',
    };
    this.colorPickerContainer = React.createRef();
  }

  handleTouchStart = () => {
    if (this.colorPickerContainer.current) {
      this.colorPickerContainer.current.addEventListener(
        'touchmove',
        event => {
          event.preventDefault();
        },
        false
      );
    }
  };

  handleTouchEnd = () => {
    if (this.colorPickerContainer.current) {
      this.colorPickerContainer.current.removeEventListener('touchmove');
    }
  };

  handleChange = color => {
    const { onChange } = this.props;
    this.setState({ color: color.hex });
    onChange(color);
  };

  render() {
    const { color } = this.state;
    const { name } = this.props;

    return (
      <div className="color-picker">
        <Popover
          buttonClassname="btn color-picker__button"
          id={name}
          trigger={
            <React.Fragment>
              <span className="color-picker__color">{color}</span>
              <div
                className="color-picker__color-view"
                style={{ backgroundColor: color }}
              />
            </React.Fragment>
          }
        >
          <div
            onTouchStart={this.handleTouchStart}
            onTouchEnd={this.handleTouchEnd}
            ref={this.colorPickerContainer}
          >
            <ChromePicker
              color={color}
              onChange={this.handleChange}
              disableAlpha
            />
          </div>
        </Popover>
      </div>
    );
  }
}

const renderChromeColorPickerField = props => {
  const {
    input,
    field,
    form: { touched = {}, errors = {} },
    hasAttemptedSubmit,
  } = props;
  return (
    <div className="form__form-group-input-wrap--error-below">
      <ChromeColorPickerField {...input} />
      {(getIn(touched, field.name) || hasAttemptedSubmit) &&
        getIn(errors, field.name) && (
          <FormFeedback
            className="validation form__form-group-error"
            tag="small"
          >
            {getIn(errors, field.name)}
          </FormFeedback>
        )}
    </div>
  );
};

renderChromeColorPickerField.propTypes = {
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderChromeColorPickerField.defaultProps = {
  meta: null,
};

export default renderChromeColorPickerField;
