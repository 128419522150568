import React from 'react';
import { Link } from 'react-router-dom';
import { Content, BlankSlate, Button } from 'components';

const Dashboard = () => (
  <Content title="Dashboard" className="dashboard">
    <BlankSlate
      title="Welcome to the Hallmark Movie Checklist CMS."
      message="Check out all here the different sections in the sidebar on the left to add and update content."
      cta={
        <Button tag={Link} to="/home-screen" color="primary">
          Get Started
        </Button>
      }
    />
  </Content>
);

export default Dashboard;
