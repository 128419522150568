import React, { Component } from 'react';
import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import PropTypes from 'prop-types';
import './TextEditor.scss';

export default class TextEditorBase extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    let editorState;
    if (props.initialValue && props.initialValue !== '<p></p>\n') {
      editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(props.initialValue))
      );
    } else {
      editorState = EditorState.createEmpty();
    }
    this.state = {
      editorState,
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    });
    if (this.props.onChange) {
      this.props.onChange(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      );
    }
  };

  render() {
    const { editorState } = this.state;
    return (
      <Editor
        editorState={editorState}
        wrapperClassName="text-editor-container"
        toolbarClassName="text-editor-toolbar"
        editorClassName="text-editor-area"
        onEditorStateChange={this.onEditorStateChange}
        toolbar={this.props.toolbarOptions}
      />
    );
  }
}

TextEditorBase.propTypes = {
  toolbarOptions: PropTypes.shape({}),
};

TextEditorBase.defaultProps = {
  toolbarOptions: {
    // https://github.com/jpuri/react-draft-wysiwyg/issues/4#issuecomment-256276114
    options: ['blockType', 'inline', 'list', 'textAlign', 'link'],
    list: {
      options: ['unordered', 'ordered'],
    },
    inline: {
      options: ['bold', 'italic', 'underline'],
    },
  },
};
