import React from 'react';
import PropTypes from 'prop-types';
import FolderListItem from 'components/Assets/FolderListItem';
import { nothingIf } from 'shared/enhancers';

import './FolderList.scss';

const Folder = ({ folder }) => (
  <FolderListItem {...folder}>
    <Contents folders={folder.items} />
  </FolderListItem>
);

const Contents = nothingIf(x => !x.folders)(props => {
  const { folders } = props;

  return (
    <React.Fragment>
      {folders.map((folder, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Folder folder={folder} key={i} />
      ))}
    </React.Fragment>
  );
});

const FolderList = nothingIf(x => !x.folders)(props => {
  const { folders } = props;

  return (
    <div className="folder-list-container">
      <ul className="folder-list">
        <Contents folders={folders} />
      </ul>
    </div>
  );
});

FolderList.propTypes = {
  folders: PropTypes.arrayOf(PropTypes.shape()),
};

FolderList.defaultProps = {
  folders: null,
};

export default FolderList;
