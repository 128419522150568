import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Collapse } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withApi } from 'infrastructure/api';
import { withCurrentUser } from 'infrastructure/currentUser';
import TopbarMenuLink from './TopbarMenuLink';

const enhance = compose(
  withApi,
  withCurrentUser,
  connect(
    null,
    (dispatch, props) => ({
      api: {
        auth: bindActionCreators(props.api.auth.auth, dispatch),
      },
    })
  )
);

export default enhance(
  class TopbarProfile extends PureComponent {
    static propTypes = {
      api: PropTypes.shape().isRequired,
      currentUser: PropTypes.shape(),
    };

    static defaultProps = {
      currentUser: {},
    };

    constructor() {
      super();
      this.state = {
        collapse: false,
      };
    }

    toggle = () => {
      const { collapse } = this.state;
      this.setState({ collapse: !collapse });
    };

    logout = () => {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.api.auth.logout();
    };

    render() {
      const { collapse } = this.state;
      const { currentUser } = this.props;
      const fullName = [currentUser.name, ' ', currentUser.surname].join('');

      const userNameToQuery = str =>
        str &&
        str
          .split(' ')
          .map(n => n[0])
          .join(' ')
          .replace(/[^A-Z0-9]+/gi, '+');

      return (
        <div className="topbar__profile">
          {currentUser && currentUser.name && (
            <button
              type="button"
              className="topbar__avatar"
              onClick={this.toggle}
            >
              <img
                className="topbar__avatar-img"
                src={`https://ui-avatars.com/api/?name=${userNameToQuery(
                  fullName
                )}&background=316176&color=fff`}
                alt="avatar"
              />
              <p className="topbar__avatar-name">{fullName}</p>
              <FontAwesomeIcon icon={faChevronDown} className="topbar__icon" />
            </button>
          )}
          {collapse && (
            <button
              type="button"
              className="topbar__back"
              onClick={this.toggle}
            />
          )}
          <Collapse isOpen={collapse} className="topbar__menu-wrap">
            <div className="topbar__menu">
              <TopbarMenuLink
                title="Log Out"
                icon="exit-right"
                path="/login"
                onClick={() => this.logout()}
              />
            </div>
          </Collapse>
        </div>
      );
    }
  }
);
