import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isFunction } from 'lodash';
import {
  compose,
  withHandlers,
  setDisplayName,
  wrapDisplayName,
} from 'recompose';
import partial from 'lodash/partial';
import { toast } from 'components';
import { withApi } from 'infrastructure/api';
import { withPaths } from 'infrastructure/paths';
import parseValidationErrors from './parseValidationErrors';

const toastStringOrFunction = (data, target, fn) => {
  const message = isFunction(target) ? target(data) : target;

  fn(message);
};

const buildOptions = opts => ({
  deleteMessage: 'Item deleted',
  successMessage: 'Item saved',
  getSave: props => {
    if (props.match.params.id) {
      return partial(props.api.update, props.match.params.id);
    }

    return props.api.create;
  },
  modifyData: id => id,
  onSuccess: (props, data, t, options) => {
    if (options.redirectOnSave && options.returnUrl) {
      props.history.push(options.returnUrl(data, props));
    } else {
      if (!props.match.params.id) {
        props.history.push(props.paths.replace('/form', `/${data.id}/form`));
      }
      props.form.setSubmitting(false);
    }

    toastStringOrFunction(data, options.successMessage, t.success);
  },
  redirectOnSave: false,
  ...opts,
});

const apiSaveHandler = ({ mapStateToProps, ...opts }) =>
  (options => WrappedComponent =>
    compose(
      withPaths,
      setDisplayName(wrapDisplayName(WrappedComponent, 'apiSaveHandler')),
      withApi,
      connect(
        mapStateToProps,
        (dispatch, { api }) => ({
          api: bindActionCreators(options.getApi(api), dispatch),
        })
      ),
      connect(),
      withHandlers({
        onDeleted: props => () => {
          if (options.returnUrl) {
            props.history.push(options.returnUrl(props));
          } else {
            props.history.push('/');
          }

          toastStringOrFunction(
            props.current,
            options.deleteMessage,
            toast.success
          );
        },
        onSubmit: props => (data, form) => {
          const save = options.getSave(props);

          save(
            options.modifyData(data),
            options.schema && {
              schema: options.schema,
            }
          ).then(({ payload, ...res }) => {
            if (!res.error) {
              let entity = data;
              if (options.entityName) {
                const { normalized } = payload;
                entity =
                  normalized.entities[options.entityName][normalized.result];

                props.dispatch({
                  type: 'REPLACE_ENTITY',
                  payload: {
                    path: [options.entityName, normalized.result],
                    entity,
                  },
                });
              }

              options.onSuccess(
                {
                  form,
                  ...props,
                },
                entity,
                toast,
                options,
                res
              );
            } else {
              if (!payload.response) {
                toast.error(payload.message || JSON.stringify(payload));
                // eslint-disable-next-line no-console
                console.error(payload);
              } else {
                const {
                  response: { error, message },
                } = payload;

                if (error.validationErrors) {
                  const errors = parseValidationErrors(error.validationErrors);
                  form.setErrors(errors);
                }

                if (message) {
                  toast.error(message.split(/(?=[A-Z])/).join(' '));
                }
              }

              form.setSubmitting(false);
            }
          });
        },
      })
    )(WrappedComponent))(buildOptions(opts));

export default apiSaveHandler;
