import React from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withHandlers,
  withState,
  withProps,
  lifecycle,
} from 'recompose';
import moment from 'moment-timezone';
import { Row, Col } from 'reactstrap';
import { connect } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faSave,
  faEyeSlash,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { faMinusCircle } from '@fortawesome/pro-light-svg-icons';

import Modal from 'components/Modal';
import Button from 'components/Button';
import Fieldset from 'components/Form/Fieldset';
import './FormActions.scss';

const enhance = compose(
  connect,
  withState('showDeleteModal', 'toggleShowDelete', false),
  withHandlers({
    handleDelete: ({
      handleDelete,
      formik: {
        values: { id },
      },
    }) => () => handleDelete(id),
    initDelete: ({ toggleShowDelete }) => () => toggleShowDelete(true),
    cancelDelete: ({ toggleShowDelete }) => () => toggleShowDelete(false),
  }),
  withProps(props => {
    if (props.lastSaved) {
      return null;
    }

    const {
      formik: {
        values: { lastModificationTime, creationTime },
      },
    } = props;

    return {
      lastSaved: lastModificationTime || creationTime,
    };
  }),
  withProps(props => {
    const {
      formik: { status },
    } = props;

    if (status) {
      return {
        changingPublishStatus: status.changingPublishStatus,
      };
    }

    return { changingPublishStatus: false };
  }),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      if (this.props.isSubmitting && !nextProps.isSubmitting) {
        if (
          this.props.changingPublishStatus &&
          nextProps.changingPublishStatus
        ) {
          nextProps.formik.setStatus({
            ...nextProps.formik.status,
            changingPublishStatus: undefined,
          });
        }
      }
    },
  })
);

const statusString = {
  New: 'Not yet saved',
  Draft: 'Draft',
  Published: 'Published',
  Deleted: 'Deleted',
  get(key) {
    return this[key || 'New'];
  },
};

const statusColor = {
  New: 'warning',
  Draft: 'info',
  Published: 'success',
  Deleted: 'danger',
  get(key) {
    return this[key || 'New'];
  },
};

class LastSavedDisplay extends React.Component {
  static propTypes = {
    lastSaved: PropTypes.string,
  };

  static defaultProps = {
    lastSaved: null,
  };

  constructor(props) {
    super(props);
    this.ticker = setInterval(
      () =>
        this.setState({
          now: new Date(),
        }),
      1000
    );

    this.state = {
      now: new Date(),
    };
  }

  componentWillUnmount() {
    clearInterval(this.ticker);
  }

  render() {
    const { lastSaved } = this.props;
    const { now } = this.state;

    return <p>Last saved {moment(lastSaved).from(now)}</p>;
  }
}

const FormActions = ({
  initDelete,
  isComplete,
  isPublishing,
  isSubmitting,
  lastSaved,
  showPublishing,
  status,
  handleDelete,
  handleChangeStatus,
  formik: {
    dirty,
    values: { id },
  },
  showDeleteModal,
  toggleShowDelete,
  cancelDelete,
  displayName,
  changingPublishStatus,
  shouldHideDraft,
}) => (
  <div className="form-actions">
    <div className="action-group">
      <Row>
        <Col xs={{ size: '', order: 2 }}>
          <Button
            block
            className="btn-save-changes"
            color="primary"
            disabled={!dirty || isPublishing || changingPublishStatus}
            icon={
              showPublishing ? (
                <FontAwesomeIcon icon={faSave} />
              ) : (
                <FontAwesomeIcon icon={faCheck} />
              )
            }
            loading={isSubmitting && !changingPublishStatus}
            type="submit"
          >
            {showPublishing ? 'Save Changes' : 'Save'}
          </Button>
        </Col>
        {!showPublishing && id && handleDelete && (
          <Col xs={{ size: 'auto', order: 1 }}>
            <Button
              block
              buttonStyle="outline"
              color="danger"
              disabled={isSubmitting || changingPublishStatus}
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              onClick={initDelete}
              type="button"
            >
              Delete
            </Button>
          </Col>
        )}
      </Row>
      {lastSaved && <LastSavedDisplay lastSaved={lastSaved} />}
    </div>
    {showPublishing && (
      <div className="action-group">
        <Fieldset legend="Publishing">
          <p>
            Status:{' '}
            <strong className={`text-${statusColor.get(status)}`}>
              {statusString.get(status)}
            </strong>
          </p>
          <Row className="align-items-center">
            {status !== 'Deleted' && handleDelete && (
              <Col xs={6}>
                <Button
                  block
                  buttonStyle="outline"
                  color="danger"
                  disabled={
                    status === 'New' || isSubmitting || changingPublishStatus
                  }
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  onClick={initDelete}
                  size="sm"
                  type="button"
                >
                  Delete
                </Button>
              </Col>
            )}
            <Col xs={6}>
              {(status === 'Published' || status === 'Deleted') &&
              !shouldHideDraft ? (
                <Button
                  block
                  color="warning"
                  disabled={isSubmitting || changingPublishStatus}
                  icon={<FontAwesomeIcon icon={faEyeSlash} />}
                  loading={changingPublishStatus}
                  onClick={() => handleChangeStatus('Draft')}
                  size="sm"
                  type="button"
                >
                  Make Draft
                </Button>
              ) : null}
              {status !== 'Published' ? (
                <Button
                  block
                  color="success"
                  disabled={
                    !isComplete || isSubmitting || changingPublishStatus
                  }
                  icon={<FontAwesomeIcon icon={faCheck} />}
                  loading={changingPublishStatus}
                  onClick={() => handleChangeStatus('Published')}
                  size="sm"
                  type="button"
                >
                  Publish Now
                </Button>
              ) : null}
            </Col>
          </Row>
        </Fieldset>
      </div>
    )}
    {showDeleteModal && (
      <Modal
        hideButton
        show={showDeleteModal}
        color="danger"
        confirmationModal
        size="sm"
        onToggle={toggleShowDelete}
        footer={
          <Row>
            <Col xs="auto">
              <Button color="dark" outline onClick={cancelDelete}>
                <FontAwesomeIcon icon={faMinusCircle} /> Cancel
              </Button>
            </Col>
            <Col xs="auto">
              <Button color="danger" onClick={handleDelete}>
                <FontAwesomeIcon icon={faTrashAlt} /> Yes, Delete
              </Button>
            </Col>
          </Row>
        }
      >
        <h3>Are you sure you want to delete this {displayName}?</h3>
      </Modal>
    )}
  </div>
);

FormActions.propTypes = {
  cancelDelete: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  handleDelete: PropTypes.func,
  initDelete: PropTypes.func,
  displayName: PropTypes.string,
  formik: PropTypes.shape({
    dirty: PropTypes.bool.isRequired,
  }).isRequired,
  isComplete: PropTypes.bool, // Data can be published (eg. movie has all required info to go live)
  isPublishing: PropTypes.bool, // Loading state on the publish button
  isSubmitting: PropTypes.bool, // Loading state on the save button (not sure if this is a good name for the prop)
  lastSaved: PropTypes.string, // Date/time that can be passed to moment.js
  showDeleteModal: PropTypes.bool,
  showPublishing: PropTypes.bool, // Shows the publishing actions
  status: PropTypes.oneOf(Object.keys(statusString)),
  changingPublishStatus: PropTypes.bool,
  shouldHideDraft: PropTypes.bool,
  toggleShowDelete: PropTypes.func,
};

FormActions.defaultProps = {
  cancelDelete: () => {},
  displayName: 'entity',
  handleChangeStatus: () => false,
  handleDelete: null,
  initDelete: () => {},
  isComplete: false,
  isPublishing: false,
  isSubmitting: false,
  lastSaved: '',
  showDeleteModal: true,
  showPublishing: false,
  status: 'New',
  changingPublishStatus: false,
  shouldHideDraft: false,
  toggleShowDelete: null,
};

export default enhance(FormActions);
