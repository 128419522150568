import PropTypes from 'prop-types';
import { compose, setDisplayName, wrapDisplayName, withProps } from 'recompose';
import { withRouter } from 'react-router';
import URI from 'urijs';

export const propTypes = {
  paths: PropTypes.shape({
    appendToCurrent: PropTypes.func.isRequired,
  }).isRequired,
};

export default Component =>
  compose(
    setDisplayName(wrapDisplayName(Component, 'withPaths')),
    withRouter,
    withProps(props => ({
      paths: {
        appendToCurrent: segment =>
          URI(props.location.pathname)
            .segment(segment)
            .toString(),
        replace: (target, replace) =>
          props.location.pathname.replace(target, replace),
      },
    }))
  )(Component);
