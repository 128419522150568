import { compose, setDisplayName, wrapDisplayName, withProps } from 'recompose';
import { BlockBlobClient, AnonymousCredential } from '@azure/storage-blob';
import { withConfig } from 'infrastructure/config';

const withAzureUploader = Component =>
  compose(
    withConfig,
    setDisplayName(wrapDisplayName(Component, 'withUpload')),
    withProps(({ config }) => ({
      getPresingedUrl: async fileName => {
        const { apiUrl } = config;

        const finalFileName = fileName.replace(' ', '-');
        const url = `${apiUrl}/api/app/uploads/presignedUrl?fileName=${finalFileName}`;

        const request = new Request(url);
        const response = await fetch(request, { method: 'GET' });

        const sasToken = await response.json();
        return sasToken;
      },
      uploadToAzure: async ({ presignedUrl, fileName, assetUrl }, data) => {
        const base64 = data
          .replace('data:image/jpeg;base64,', '')
          .replace('data:image/jpg;base64,', '')
          .replace('data:image/png;base64,', '')
          .replace('data:image/gif;base64,', '')
          .replace('data:video/mp4;base64,', '')
          .replace('data:video/quicktime;base64,', '')
          .replace('data:application/json;base64,', '')
          .replace(' ', '');
        const buffer = Buffer.from(base64, 'base64');

        const credentials = new AnonymousCredential();
        const blockBlobClient = new BlockBlobClient(presignedUrl, credentials);

        await blockBlobClient.uploadBrowserData(buffer);

        return {
          assetUrl,
          fileName,
        };
      },
    }))
  )(Component);

export default withAzureUploader;
