import React from 'react';
import './AssetToolbar.scss';
import { ButtonGroup, Col, Label, Input, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import Button from 'components/Button';

const AssetToolbar = props => {
  const { showSearch, showSort, showFilter, showUpload } = props;
  return (
    <Row className="asset-toolbar align-items-center">
      {showSearch && (
        <Col md={6} lg={4} className="toolbar-item">
          <form className="form">
            <div className="form__form-group-field">
              <Label className="sr-only" htmlFor="assetSearch">
                Search
              </Label>
              <Input
                className="seatch-input"
                id="assetSearch"
                name="assetSearch"
                placeholder="eg. Folder or File Name"
                type="search"
              />
              <Button
                aria-label="Search Assets"
                type="button"
                className="input-group-append"
                color="secondary"
                icon={<FontAwesomeIcon icon={faSearch} />}
              />
            </div>
          </form>
        </Col>
      )}
      <Col xs={12} className="col-xl">
        <Row className="align-items-center justify-content-end">
          {showSort && (
            <Col xs={12} md="auto" className="toolbar-item">
              <Row className="align-items-center">
                <Col xs="auto">
                  <Label htmlFor="sortBy">Sort By:</Label>
                </Col>
                <Col>
                  <select
                    className="form-control"
                    id="sortBy"
                    name="sortBy"
                    openMenuOnFocus
                  >
                    <option value="1">Date Added</option>
                    <option value="2">Date Modified</option>
                    <option value="3">Name</option>
                  </select>
                </Col>
              </Row>
            </Col>
          )}
          {showFilter && (
            <Col xs={12} md="auto" className="toolbar-item">
              <ButtonGroup className="justified">
                <Button type="button" color="primary" size="sm">
                  All
                </Button>
                <Button type="button" color="primary" outline size="sm">
                  Folders
                </Button>
                <Button type="button" color="primary" outline size="sm">
                  Images
                </Button>
                <Button type="button" color="primary" outline size="sm">
                  Videos
                </Button>
              </ButtonGroup>
            </Col>
          )}
          {showUpload && (
            <Col xs="auto" className="toolbar-item">
              <Button
                type="button"
                icon={<FontAwesomeIcon icon={faPlus} />}
                color="primary"
              >
                New
              </Button>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

AssetToolbar.propTypes = {
  showSearch: PropTypes.bool,
  showSort: PropTypes.bool,
  showFilter: PropTypes.bool,
  showUpload: PropTypes.bool,
};

AssetToolbar.defaultProps = {
  showSearch: true,
  showSort: true,
  showFilter: true,
  showUpload: true,
};

export default AssetToolbar;
