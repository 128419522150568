import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import './RelativeTime.scss';

class RelativeTime extends React.Component {
  static propTypes = {
    dateTime: PropTypes.string,
    format: PropTypes.string,
  };

  static defaultProps = {
    dateTime: null,
    format: 'YYYY-MM-DD HH:MM.s',
  };

  constructor(props) {
    super(props);
    this.ticker = setInterval(
      () =>
        this.setState({
          now: new Date(),
        }),
      1000
    );

    this.state = {
      now: new Date(),
    };
  }

  componentWillUnmount() {
    clearInterval(this.ticker);
  }

  render() {
    const { dateTime, format } = this.props;
    const { now } = this.state;

    const time = moment(dateTime);

    if (!time.isValid()) return <React.Fragment />;

    return (
      <span className="relative-time" title={time.format(format)}>
        {time.from(now)}
      </span>
    );
  }
}

export default RelativeTime;
