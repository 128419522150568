import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { startCase } from 'lodash';
import { processValidate } from 'infrastructure/form';
import renderRadioButtonField from 'shared/components/form/RadioButton';

const RadioButtonField = props => {
  const { validate, label, name, radioValue, defaultChecked } = props;
  return (
    <Field
      {...props}
      type="radio"
      name={name}
      label={label}
      validate={processValidate(label || startCase(name), validate, props)}
      render={({ form, field, ...p }) =>
        renderRadioButtonField({
          input: {
            ...field,
            onChange: value => form.setFieldValue(name, value),
            value: form.values[name] || '',
          },
          label,
          radioValue,
          defaultChecked,
          ...p,
        })
      }
    />
  );
};
RadioButtonField.propTypes = {
  validate: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  name: PropTypes.string,
};
RadioButtonField.defaultProps = {
  validate: [],
  label: '',
  name: '',
};

export default RadioButtonField;
