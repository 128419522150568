import { createApiReducer } from 'infrastructure/api';

export const initialState = { currentUser: {} };

const handlers = {
  'identity/profile/GET': (state, payload) => payload || null,
};

const reducer = createApiReducer(handlers, initialState);

const currentUserReducer = (state = initialState, action) => {
  if (action.type === 'CLEAR_AUTH') {
    return {
      timedOut: true,
    };
  }

  return reducer(state, action);
};

export default currentUserReducer;
