import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { Input, FormFeedback } from 'reactstrap';
import { getValidity } from './validate';

const renderField = ({
  field,
  form,
  hasAttemptedSubmit,
  defaultValue,
  ...props
}) => {
  //  initialize field value if it has a default #1731
  if (!field.value && defaultValue && !getIn(form.touched, field.name)) {
    form.setFieldValue(field.name, defaultValue);
  }

  return (
    <div className="form__form-group-input-wrap--error-below">
      <Input
        {...props}
        {...field}
        {...getValidity(
          getIn(form.touched, field.name),
          getIn(form.errors, field.name),
          hasAttemptedSubmit
        )}
        defaultValue={defaultValue}
      />
      {(getIn(form.touched, field.name) || hasAttemptedSubmit) &&
        getIn(form.errors, field.name) && (
          <FormFeedback
            className="validation form__form-group-error"
            tag="small"
          >
            {getIn(form.errors, field.name)}
          </FormFeedback>
        )}
    </div>
  );
};

renderField.propTypes = {
  field: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

export default renderField;
