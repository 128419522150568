export const CHANGE_THEME_TO_DARK = 'CHANGE_THEME_TO_DARK';
export const CHANGE_THEME_TO_LIGHT = 'CHANGE_THEME_TO_LIGHT';
export const CHANGE_THEME_TO_LIGHTONDARK = 'CHANGE_THEME_TO_LIGHTONDARK';
export const MODIFY_THEME = 'MODIFY_THEME';

export function changeThemeToDark() {
  return {
    type: CHANGE_THEME_TO_DARK,
  };
}

export function changeThemeToLight() {
  return {
    type: CHANGE_THEME_TO_LIGHT,
  };
}

export function changeThemeToLightOnDark() {
  return {
    type: CHANGE_THEME_TO_LIGHTONDARK,
  };
}

export function modifyTheme(payload) {
  return {
    type: MODIFY_THEME,
    payload,
  };
}
