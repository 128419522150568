import React from 'react';
import classnames from 'classnames';
import {
  Modal as ModalContainer,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Button } from 'components';
import './Modal.scss';
import PropTypes from 'prop-types';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.show,
    };
    this.onToggle = props.onToggle;
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (this.onToggle) {
      const { isOpen } = this.state;
      this.onToggle(!isOpen);
    }
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
    document.activeElement.blur();
  }

  render() {
    const {
      buttonContents,
      buttonProps,
      children,
      className,
      color,
      footer,
      hideButton,
      size,
      title,
    } = this.props;

    const { isOpen } = this.state;

    return (
      <React.Fragment>
        {!hideButton && (
          <Button {...buttonProps} onClick={this.toggle}>
            {buttonContents}
          </Button>
        )}
        <ModalContainer
          className={classnames(className, color)}
          isOpen={isOpen}
          size={size}
          toggle={this.toggle}
        >
          <Button
            className="close-btn btn-icon rounded-pill"
            color="light"
            onClick={this.toggle}
            type="button"
            icon={<span className="lnr lnr-cross" />}
          />
          {title && <ModalHeader>{title}</ModalHeader>}
          {children && <ModalBody>{children}</ModalBody>}
          {footer && <ModalFooter>{footer}</ModalFooter>}
        </ModalContainer>
      </React.Fragment>
    );
  }
}

Modal.propTypes = {
  onToggle: PropTypes.func,
  buttonContents: PropTypes.string,
  buttonProps: PropTypes.shape({}),
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    null,
  ]),
  footer: PropTypes.node,
  hideButton: PropTypes.bool,
  size: PropTypes.oneOf(['', 'sm', 'lg']),
  title: PropTypes.node,
};

Modal.defaultProps = {
  buttonContents: '',
  buttonProps: null,
  children: null,
  className: '',
  color: null,
  footer: null,
  hideButton: false,
  size: '',
  title: null,
  onToggle: null,
};

export default Modal;
