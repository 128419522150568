import { takeEvery, call } from 'redux-saga/effects';
import { toast } from 'components';

export function* handle500(action) {
  yield call(() => toast.error(action.payload.message));
}

export function* handle400(action) {
  yield call(() => toast.error(action.payload.response.error.message));
}

function* erroListener() {
  yield takeEvery(
    x =>
      (x.type === 'API_FAILURE' && x.payload.status === 500) ||
      (x.type === 'API_REQUEST' && x.error),
    handle500
  );

  yield takeEvery(
    x => x.type === 'API_FAILURE' && x.payload.status === 400,
    handle400
  );
}

export default erroListener;
