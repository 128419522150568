/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
import React, { PureComponent } from 'react';
import { Button, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import './Fieldset.scss';

export default class Fieldset extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    closed: PropTypes.bool,
    collapsible: PropTypes.bool,
    legend: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    closed: false,
    collapsible: false,
    legend: '',
  };

  constructor(props) {
    super();
    this.state = {
      collapse: !props.closed,
      statusClass: props.closed ? 'closed' : 'opened',
    };
  }

  onEntering = () => {
    this.setState({ statusClass: 'opening' });
  };

  onEntered = () => {
    this.setState({ statusClass: 'opened' });
  };

  onExiting = () => {
    this.setState({ statusClass: 'closing' });
  };

  onExited = () => {
    this.setState({ statusClass: 'closed' });
  };

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { children, className, collapsible, legend, closed } = this.props;
    const shouldShowButton = collapsible || (!collapsible && closed);
    return (
      <fieldset
        className={`
          fieldset
          collapse__wrapper
          ${this.state.statusClass}
          ${className}`}
      >
        <legend>
          {legend}
          {shouldShowButton && (
            <Button
              aria-label={this.state.collapse ? 'Close' : 'Open'}
              onClick={this.toggle}
              className="btn-light icon rounded fieldset-right-icon"
              color="link"
              outline
              type="button"
            >
              <FontAwesomeIcon icon={faChevronUp} />
            </Button>
          )}
        </legend>
        <Collapse
          className="fieldset-content"
          isOpen={this.state.collapse}
          onEntering={this.onEntering}
          onEntered={this.onEntered}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          {children}
        </Collapse>
      </fieldset>
    );
  }
}
/* eslint-enable react/destructuring-assignment */
/* eslint-enablereact/no-access-state-in-setstate */
