import { withFormik } from 'formik';
import { compose, withHandlers, withProps } from 'recompose';

const state = {
  Draft: () => true,
  Published: props => (props.dirty && props.isValid) || true,
};

export default opts =>
  compose(
    withFormik({
      handleSubmit: (data, { props, ...form }) => props.onSubmit(data, form),
      ...opts,
    }),
    withHandlers({
      handleChangeStatus: props => async status => {
        const errors = await props.validateForm();
        props.submitForm();
        if (
          state[status] &&
          state[status](props) &&
          (Object.keys(errors).length === 0 && errors.constructor === Object)
        ) {
          props.setFieldValue('status', status);
          props.setStatus({
            ...props.status,
            changingPublishStatus: true,
          });
        }
      },
      handleDelete: ({ api, onDeleted = () => {} }) => {
        if (!api || !api._delete) return null;

        return id => {
          api._delete(id);
          onDeleted();
        };
      },
    }),
    withProps(({ submitCount }) => ({
      hasAttemptedSubmit: submitCount > 0,
    }))
  );
