import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import renderDropZoneField from 'components/Form/DropZoneField';
import './DropZone.scss';

const defaultEmptyMessage = 'Drop a file here or click to upload';

const wrapValue = value => {
  if (!value) return value;

  if (!Array.isArray(value)) {
    return [value];
  }

  return value;
};

const DropZone = props => {
  const {
    className,
    fieldProps,
    emptyMessage,
    multiple,
    name,
    onChange,
    onFileDialogCancel,
  } = props;
  return (
    <Field
      name={name}
      render={({ form, field }) =>
        renderDropZoneField({
          className,
          emptyMessage,
          fieldProps,
          input: {
            ...field,
            value: wrapValue(field.value, multiple),
            onFileDialogCancel,
            onChange: files => {
              if (multiple || !files || !Array.isArray(files)) {
                form.setFieldTouched(name);
                form.setFieldValue(name, files);
                onChange(files);
                return;
              }
              const [file] = files;

              form.setFieldValue(name, file);
              onChange(file);
            },
          },
          multiple,
        })
      }
    />
  );
};

DropZone.propTypes = {
  className: PropTypes.string,
  emptyMessage: PropTypes.node,
  fieldProps: PropTypes.shape({}),
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
};

DropZone.defaultProps = {
  className: '',
  emptyMessage: defaultEmptyMessage,
  fieldProps: {
    accept: 'image/*, video/*',
  },
  multiple: false,
  name: '',
  onChange: () => {},
  onFileDialogCancel: () => {},
};

export default DropZone;
