import React from 'react';
import { Field, getIn } from 'formik';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinusCircle,
  faPlus,
  faSpinnerThird,
  faFileImage,
  faFileVideo,
} from '@fortawesome/pro-solid-svg-icons';
import { Button, Img, RelativeTime, Alert } from 'components';
import { DropZone } from 'components/Form';
import TextField from 'components/Form/TextField';
import withAzureUploader from 'infrastructure/assets/withAzureUploader';
import { withApi } from 'infrastructure/api';
import { withConfig } from 'infrastructure/config';
import { startCase } from 'lodash';
import { FormFeedback } from 'reactstrap';
import assetPathHelper from 'infrastructure/assets/assetPathHelper';

function setupReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = e => {
      resolve(e);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

async function HandleInitialUploadToAzure(
  fileName,
  uploadToAzure,
  data,
  config,
  getPresignedUrl
) {
  const presignedUrl = await getPresignedUrl(fileName, config);
  const response = await uploadToAzure(presignedUrl, data);
  return response;
}

const uploadFile = (
  name,
  form,
  urlType,
  uploadToAzure,
  config,
  getPresignedUrl
) => async file => {
  if (!file) return;

  form.setSubmitting(true);

  const result = await setupReader(file);

  const upload = await HandleInitialUploadToAzure(
    file.name,
    uploadToAzure,
    result.target.result,
    config,
    getPresignedUrl
  );

  const resource = {
    fileName: file.name,
    mimeType: file.type,
    size: file.size,
    preview: file.preview,
    urlType,
    type: 'uploadType',
    originalUrl: upload.assetUrl,
    error: false,
  };

  form.setFieldValue(`${name}Input`, resource);
  form.setSubmitting(false);
};

const ExtraProperties = props => {
  const { name: parentName, id } = props;

  const name = `${parentName}.extraProperties`;

  return (
    <React.Fragment>
      <Field
        id={id || name}
        name={name}
        render={({ form }) => {
          const parent = getIn(form.values, parentName, {});
          const value = getIn(form.values, name, {});

          if (!value || !parent.id) {
            return <React.Fragment></React.Fragment>;
          }
          const keys = Object.keys(value);

          return (
            <React.Fragment>
              {keys.map(x => (
                <React.Fragment>
                  <TextField name={`${name}.${x}.key`} />
                  <TextField
                    name={`${parentName}Input.${getIn(value, `${x}.key`)}`}
                  />
                </React.Fragment>
              ))}
              <small>
                Metadata{' '}
                <Button
                  color="primary"
                  size="sm"
                  icon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => {
                    const newProps = {
                      [+new Date()]: { key: `meta1`, value: '' },
                    };
                    form.setFieldValue(`${name}`, newProps);
                    form.setFieldValue(`${parentName}Input`, {
                      id: parent.id,
                      meta1: '',
                    });
                    document.activeElement.blur();
                  }}
                >
                  Add
                </Button>
              </small>
            </React.Fragment>
          );
        }}
      />
    </React.Fragment>
  );
};

ExtraProperties.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
};
ExtraProperties.defaultProps = {
  name: null,
  id: null,
};

const enhance = compose(
  withAzureUploader,
  withApi,
  withConfig
);

const AssetSelector = props => {
  const {
    name,
    id,
    type,
    uploadToAzure,
    config,
    getPresingedUrl,
    required,
    accept,
    hasAttemptedSubmit,
  } = props;

  return (
    <div className="form__form-group-input-wrap--error-below">
      <Field
        id={id || name}
        name={name}
        validate={value => {
          if (!required) return null;

          const result =
            !value ||
            (!value.input && !value.id) ||
            (value.error && !value.input) ||
            (value.deleted && !value.input)
              ? `${startCase(name)} is required`
              : null;

          return result;
        }}
        render={({ form }) => {
          const value = getIn(form.values, name, {});
          const error = getIn(form.errors, name);
          const touched = getIn(form.touched, name);
          const showError = error && touched;
          const isValid = touched && !error;

          const dropZoneClassName = classnames({
            'is-valid': isValid,
            'is-invalid': showError,
          });

          if (!value || !value.id || value.cleared) {
            return (
              <React.Fragment>
                <DropZone
                  onChange={uploadFile(
                    name,
                    form,
                    type,
                    uploadToAzure,
                    config,
                    getPresingedUrl
                  )}
                  name={`${name}.input`}
                  fieldProps={{
                    accept,
                  }}
                  className={dropZoneClassName}
                  onFileDialogCancel={() => form.setFieldTouched(name)}
                />
                {(getIn(form.touched, name) || hasAttemptedSubmit) &&
                  getIn(form.errors, name) && (
                    <FormFeedback
                      className="validation form__form-group-error"
                      tag="small"
                    >
                      {getIn(form.errors, name)}
                    </FormFeedback>
                  )}
              </React.Fragment>
            );
          }

          let { title } = value;

          if (value.status !== 'None') {
            title = value.status;
          }

          if (value.status === 'Error') {
            title = `Processing error`;
            return (
              <React.Fragment>
                <Alert color="danger" dismissable={false}>
                  <h4 className="alert-heading">{title}</h4>
                  <p className="text-monospace">{value.errorMessage}</p>
                  <hr />
                  <div className="mb-0">
                    <RelativeTime dateTime={value.lastModificationTime} />
                  </div>
                </Alert>
                <DropZone
                  onChange={uploadFile(
                    name,
                    form,
                    type,
                    uploadToAzure,
                    config,
                    getPresingedUrl
                  )}
                  name={`${name}.input`}
                  className={dropZoneClassName}
                  fieldProps={{
                    accept,
                  }}
                />
              </React.Fragment>
            );
          }

          let preview = null;
          if (value.status !== 'None') {
            title = (
              <React.Fragment>
                {value.status}{' '}
                <RelativeTime
                  dateTime={value.lastModificationTime || value.creationTime}
                />
              </React.Fragment>
            );
            preview = (
              <FontAwesomeIcon
                icon={faSpinnerThird}
                className="placeholder-icon"
                spin
              />
            );
          } else if (value.type === 'Image') {
            preview = (
              <Img
                blurFill
                lightenBackground
                src={assetPathHelper(
                  config.cdnUrl,
                  (
                    getIn(value, 'urls', []).find(x => x.name === 'main') ||
                    getIn(value, 'urls', []).find(
                      x => x.name === 'processing'
                    ) || {
                      path: '',
                    }
                  ).path
                )}
                alt={title}
                className="file-thumbnail"
              />
            );
          } else if (value.type === 'Video') {
            preview = (
              <FontAwesomeIcon
                icon={faFileVideo}
                className="placeholder-icon"
              />
            );
          } else {
            preview = (
              <FontAwesomeIcon
                icon={faFileImage}
                className="placeholder-icon"
              />
            );
          }

          const processedAsset = getIn(value, 'urls', []).find(
            x => x.name === 'main'
          );

          if (processedAsset && processedAsset.path && preview) {
            preview = (
              <a
                href={assetPathHelper(config.cdnUrl, processedAsset.path)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {preview}
              </a>
            );
          }

          return (
            <React.Fragment>
              <div className="file-preview">
                {!!preview && preview}
                <small className="file-name">{title}</small>
                <Button
                  className="remove btn-icon rounded-circle"
                  color="danger"
                  icon={<FontAwesomeIcon icon={faMinusCircle} />}
                  onClick={() => {
                    form.setFieldValue(name, {
                      deleted: true,
                    });

                    form.setFieldValue(`${name}Input`, {
                      id: value.id,
                      deleted: true,
                    });
                    form.setFieldTouched(name);
                  }}
                  size="sm"
                />
              </div>
            </React.Fragment>
          );
        }}
      />
    </div>
  );
};

AssetSelector.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  uploadToAzure: PropTypes.func.isRequired,
  config: PropTypes.shape({}).isRequired,
  getPresingedUrl: PropTypes.func.isRequired,
  required: PropTypes.bool,
  accept: PropTypes.string,
  hasAttemptedSubmit: PropTypes.bool,
};
AssetSelector.defaultProps = {
  name: null,
  id: null,
  type: 'image',
  required: false,
  accept: 'image/*, video/*',
  hasAttemptedSubmit: false,
};

export default enhance(AssetSelector);
