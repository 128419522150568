import { takeEvery, call, put } from 'redux-saga/effects';
import { apiManager } from 'infrastructure/api';
import browserHistory from 'infrastructure/history';
import { deleteCookie } from 'infrastructure/security/cookies';

export function* logoutUser() {
  yield put({
    type: 'CLEAR_AUTH',
  });

  yield put(apiManager.api.auth.auth.logout());
  deleteCookie('app');

  yield call(() => browserHistory.replace('/login'));
}

function* handleAuthEror() {
  yield logoutUser();
}

function* refreshToken() {
  if (apiManager) {
    yield put(apiManager.api.auth.auth.refresh());
  }
}

function* sagas() {
  yield takeEvery(
    x =>
      x.type === 'API_FAILURE' &&
      (x.payload.status === 401 || x.payload.status === 403),
    handleAuthEror
  );

  yield takeEvery(
    x =>
      x.type === 'API_SUCCESS' &&
      !x.meta.type.includes('auth') &&
      x.meta.type !== 'identity/profile/GET',
    refreshToken
  );
}

export default sagas;
