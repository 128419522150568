import { uniq } from 'lodash';
import states from './states';

const update = (items, u) => {
  const result = uniq(u(items));

  return {
    totalCount: result.length,
    result,
  };
};

export default ({
  GET_LIST,
  GET_ADMIN_LIST,
  DELETE,
  UPDATE,
  GET,
  GET_ADMIN,
  CREATE,
}) => ({
  [GET_LIST]: (state, payload) => ({
    ...state,
    result: payload.normalized.result,
    totalCount: payload.totalCount,
    status: states.loaded,
  }),
  [GET_ADMIN_LIST]: (state, payload) => ({
    ...state,
    result: payload.normalized.result,
    totalCount: payload.totalCount,
    status: states.loaded,
  }),
  [DELETE]: ({ result, ...state }, _, meta) => ({
    ...state,
    ...update(result, i => i.filter(x => x !== meta.id)),
    status: states.loaded,
  }),
  [CREATE]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [UPDATE]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [GET]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  [GET_ADMIN]: ({ result, ...state }, payload) => ({
    ...state,
    ...update(result, i => [...i, payload.id]),
    status: states.loaded,
  }),
  default: state => state,
});
